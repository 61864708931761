html {
  transition: ease-in;
}

.infoDiv {
    color: #32383d;
    text-align: center;
    float: left;
    width: 20vw;
    margin-inline: 1em;
    z-index: 2;
    background-color: #f3f6f4;
    padding: 1em;
    border-radius: 2em;
    height: fit-content;
    overflow: scroll;
    margin: 1em;
}

.infoDiv * {
    background-color: transparent;
}

.iconContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
    margin-top: 1em;
}

@keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }


.icons {
    margin-inline: .25em;
    font-size: 2.5vw;
    background-color: transparent;
}

.ucfLogo img {
  margin-inline: .25em;
  font-size: 5vw;
  max-height: 10vh;
  background-color: transparent;
  max-width: 100%;
}

.icons img {
    width: 100%;
    max-height: 25px;
}

.title {
    font-size: 2.5vw;
    line-height: 1em;
    white-space: pre;
}

.mainContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  overflow: hidden;
  justify-content: center;
}

.aboutMePage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75vw;
  flex-wrap: wrap;
  background-color: #EBEADD;
  border-radius: 2em;
  height: 95vh;
  overflow: scroll;
  margin: 2.5vh;
}


.aboutMe img {
  border-radius: 50%;
  max-width: 100%;
  height: auto;
  max-height: 35vh;
  border-radius: 50%;
}

.titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#9dbcbc, #006666), linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0));
    background-size: 0 2px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .2s ease-out;
    padding: .5em;
    cursor: pointer;  
}
.titleContainer:hover{
    background-size: 100% 2px, auto;
}

.titleIcon {
    font-size: 4em;
    background-color: transparent;
    color: #9dbcbc;
}

.infoType {
    color: #32383d;
    font-size: 2vh;
}

.infoCursor {
  color: #32383d;
}

.returnWrapper {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    margin: 2em;
    padding-left: 1em;
    color: #006666;
    cursor: pointer;
    background-image: linear-gradient(#9dbcbc, #006666), linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0));
    background-size: 0 2px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .2s ease-out;
    z-index: 3;
    text-overflow: clip;
    float: left;
    text-align: left;
}

.returnWrapper svg {
  transition: all .5 ease-in-out;
  -webkit-transition: -moz-transform .3s ease-out; 
  -moz-transition: -webkit-transform .3s ease-out; 
  stroke:#fff;
  stroke-width: 20;
  stroke-linecap: round;
  font-size: 2em;
}

.returnWrapper:hover{
  color: #9dbcbc;
}

.dataHolder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data {
  height: 20vh;
}

.dataContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutMe {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  align-items: center;
  justify-content: center;
}

:root {
  --border: .15em;
  --color1: #006666;
  --color2: #9dbcbc;
  --from: 30deg;
  --distance: 33.33%;
}

.imgBorder {
  position: relative;
  padding: 1em;
}
.imgBorder::after {
  content: '';
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: conic-gradient(
    from var(--from),
    var(--color1),
    var(--color2) var(--distance),
    transparent var(--distance)
  );
  -webkit-mask: radial-gradient(
    farthest-side,
    transparent calc(100% - var(--border)),
    #fff calc(100% - var(--border) + 1px)
  );
  mask: radial-gradient(
    farthest-side,
    transparent calc(100% - var(--border)),
    #fff calc(100% - var(--border) + 1px)
  );
}

.aboutMe p {
  display: inline;
  height: 100%;
  font-size: 1em;
  width: 45vw;
  text-align: left;
}

.aboutMeHeading {
  font-size: 2em;
  font-weight: 700;
  text-align: left;
  margin: 0;
}
.text {
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 100%;
  margin-left: 2vw;
}

.returnHome {
  float: left;
  width: 100%;
  padding: -1em;
}

.aboutMeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
}

.form {
  display: flex;
  flex-direction: column;
  width: 55vw;
  margin: 1em auto;
  background-color: #f3f6f4;
  padding: 2em;
  border-radius: 1em;
  color: #32383d;
}

.form input, textarea {
  border: 1px solid #ccc;
  border-radius: 1em;
  margin-bottom: 1vh;
  font-size: 1rem;
  resize: none;
  width: 95%;
  padding: 1vh;
}

.form textarea {
  height: 20vh;
}

.form button {
  padding: 0.5rem 1rem;
  background-color: #006666;
  color: #fff;
  border: none;
  border-radius: 1em;
  font-size: 1rem;
  cursor: pointer;
  transition: .3s ease-in;
  font-weight: 700;
}

.form button:hover {
  background-color: #9dbcbc;
  color: #32383d;
}

.formHeader {
  text-align: center;
  margin: .25em;
  color: #32383d;
}