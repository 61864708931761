html {
  overflow: hidden;
}

body {
  font-size: 100%;
  font-family: 'DM Serif Text', serif;
  background-color: #fff;
  overflow: hidden;
  width: 100vw;
  position: relative;
}

.container {
  display: flex;
  justify-content: center;
  line-height: 1em;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;
  flex-direction: column;
}

.mainInfo {
  margin-top: 10vh;
}

.myName {
  font-size: 10vw;
  min-width: 100%;
  line-height: 1em;
  font-weight: 400;
  color: #32383c;
  margin: 0;
  background-image: linear-gradient(#32383c, #32383c), linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0));
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all .2s ease-out;
}


.commitment {
  font-size: 2.5vw;
}

.commitment p {
  display: inline-block;
  white-space: pre;
  color: #32383c;
}

.Typewriter {
  display: inline-block;
  color: #006666;
}

.myProjects {
  color: #32383c;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.myProjects h1 {
  font-size: 1em;
  font-weight: 200;
}

.aboutMeLink {
  font-size: .8em;
  margin-bottom: 10vh;
  margin-top: 0;
}

.backgroundName {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 50vh;
  line-height: 1em;
  font-weight: 400;
  color: rgba(50, 56, 60, .05);
  margin: 0;
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all .2s ease-out;
  pointer-events: none;
  z-index: -2;
}

.foregroundName {
  position: absolute;
  line-height: .95em;
  font-size: 10vw;
  margin: 0;
  top: 21%;
  z-index: -1;
  color: #9dbcbc;
  font-weight: 100;
}

.linksTitle {
  width: 100vw;
  text-align: center;
}

.links {
  width: 100vw;
  text-align: center;
}


.button {
  color: #006666;
  display: inline-block;
  padding: 1em;
  background-image: linear-gradient(#9dbcbc, #006666), linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0));
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all .2s ease-out;
  padding: .5em;
  cursor: pointer;
  margin-inline: 1em;
  text-decoration: none;
}

.socials {
  position: absolute;
  margin: 1em;
  right: 0;
}

.socialIcons {
  color: #006666;
  margin-inline: 0.1em;
  font-size: 2.5vw;
  background-color: transparent;
  transition: all ease-in .3s;
  cursor: pointer;
}

.socials svg {
  min-width: 4vw;
  min-height: 3.5vh;
}

.socialIcons:hover {
  color: #9dbcbc;
}

.modalIcons {
  color: #006666;
  margin-inline: 0.1em;
  height: 100%;
  transition: all ease-in .3s;
}

.modalIcons:hover {
  color: #9dbcbc;
}

.button:hover{
  background-size: 100% 2px, auto;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;

}

.overlay {
  background-color: rgba(50, 56, 60, .6);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modalContent {
  width: 60vw;
  background-color: #f3f6f4;
  padding: 2vw;
  border-radius: 1em;
  box-shadow: 0px 10px 25px #32383c;
}

.modalContent img {
  width: 50%;
  float: left;
  background-color: white;
  padding: 1em;
  margin: 1em;
  box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
  -moz-box-shadow: 0 1px 2px rgba(34,25,25,0.4);
  -webkit-box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
}

.modalContent h1 {
  margin: 0;
}

.modal button {
  padding: 0.5rem 1rem;
  background-color: #006666;
  color: #fff;
  border: none;
  border-radius: 1em;
  font-size: 1rem;
  cursor: pointer;
  transition: .3s ease-in;
  font-weight: 700;
}

.modal button:hover {
  background-color: #9dbcbc;
  color: #32383d;
}
